<template>
    <div class="white">
        <v-row justify="center" align="center" class="text-center">
            <v-container>
                <v-avatar size="200" tile>
                    <v-container>
                      <v-img :src="$config.logo" />
                    </v-container>
                  </v-avatar>
                  <h2 class="mt-2" style="color: #122940">
                    {{ $config.title }}
                  </h2>
                  <h2 class="mt-2" style="color: #122940">
                    منظومة سير العمل الإداري
                  </h2>
                  <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
            </v-container>
        </v-row>
        <br />
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-alert text color="primary" class="text-center">
                        <h2>
                        الخدمات الالكترونية
                        </h2>
                    </v-alert>
                </v-col>
                <v-col v-for="(item, index) in items" :key="index" cols="12" md="4">
                    <v-card outlined id="homeCards" :to="`/visitor/createOrder/${item.id}`">
                        <v-container>
                            <h3>{{ item.name }}</h3>
                        </v-container>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
export default {
    data() {
        return {
            items:[],
            loading: false
        }
    },

    created() {
        this.getItems()
    },

    methods: {
        async getItems(){
            this.loading = true
            try {
                let res = await this.$http.get(`workflow`)
                this.items = res.data.result
            } catch (error) {
                console.log(error)
            }finally{
                this.loading = false
            }
        }
    },
}
</script>